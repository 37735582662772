import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Row } from 'react-bootstrap';

const SystemRequirementsGrid = (props) => {
    return (
        <Container>
            <Row className='justify-content-center mb-5'>
                <Col lg='8' md='10' className='text-center'>
                    <h3>Minimum Device Requirements</h3>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col className='text-center'>
                    <FontAwesomeIcon
                        fixedWidth
                        icon={['light', 'wifi']}
                        className='primary mb-3'
                        size='2xl'
                    />
                    <h5 className='mb-2'>Connectivity</h5>
                    <p className='sm mt-2'>Reliable high-speed internet access</p>
                </Col>
                <Col className='text-center'>
                    {/* <img src='/images/icon-windows.png' alt='Windows' className='mb-3' /> */}
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='32'
                        height='32'
                        viewBox='0 0 32 32'
                        className='mb-3'
                    >
                        <title id='title' lang='en'>
                            Windows
                        </title>
                        <path
                            id='windows'
                            d='M0,36.407,13.114,34.6V47.271H0ZM0,59.593,13.114,61.4V48.886H0Zm14.557,2L32,64V48.886H14.557Zm0-27.186V47.271H32V32Z'
                            transform='translate(0 -32)'
                            fill='#0067b9'
                        />
                    </svg>

                    <h5 className='mb-2'>Operating System</h5>
                    <p className='sm mt-2'>Windows 10</p>
                </Col>
                <Col className='text-center'>
                    {/* <img src='/images/icon-usb.png' alt='USB' className='mb-3' /> */}
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='36'
                        height='32'
                        viewBox='0 0 36 32'
                        className='mb-3'
                    >
                        <title id='title' lang='en'>
                            USB
                        </title>
                        <g id='Group_6520' data-name='Group 6520' transform='translate(-757 -869)'>
                            <rect
                                id='Rectangle_4515'
                                data-name='Rectangle 4515'
                                width='36'
                                height='32'
                                transform='translate(757 869)'
                                fill='rgba(255,255,255,0)'
                            />
                            <path
                                id='usb'
                                d='M36,71.094a.471.471,0,0,1-.253.421l-5,3a.5.5,0,0,1-.253.079.524.524,0,0,1-.253-.062.5.5,0,0,1-.253-.438v-2h-13.4c1.42,2.222,2.273,6,3.906,6H22v-1.5a.494.494,0,0,1,.5-.5h5a.493.493,0,0,1,.5.5v5a.493.493,0,0,1-.5.5h-5a.493.493,0,0,1-.5-.5v-1.5H20.5c-4.231,0-4.551-8-7-8H7.873a4,4,0,1,1,0-2c2.194,0,2.464.533,4.186-3.39,2.25-5,3.26-4.607,6.111-4.607a3,3,0,1,1,0,2.009H16.5c-1.633,0-2.486,3.782-3.906,6H30v-2a.5.5,0,0,1,.253-.438.471.471,0,0,1,.5.017l5,3A.429.429,0,0,1,36,71.094Z'
                                transform='translate(757 813.9)'
                                fill='#0067b9'
                            />
                        </g>
                    </svg>

                    <h5 className='mb-2'>Ports</h5>
                    <p className='sm mt-2'>1x USB-A</p>
                </Col>
                <Col className='text-center'>
                    <FontAwesomeIcon
                        fixedWidth
                        icon={['light', 'microchip']}
                        className='primary mb-3'
                        size='2xl'
                    />
                    <h5 className='mb-2'>Processor</h5>
                    <p className='sm mt-2'>Intel i5 with 1.7 GHz</p>
                </Col>
                <Col className='text-center'>
                    <FontAwesomeIcon
                        fixedWidth
                        icon={['light', 'memory']}
                        className='primary mb-3'
                        size='2xl'
                    />
                    <h5 className='mb-2'>Memory</h5>
                    <p className='sm mt-2'>8GB DDR4 RAM</p>
                </Col>
                <Col className='text-center'>
                    <FontAwesomeIcon
                        fixedWidth
                        icon={['light', 'hard-drive']}
                        className='primary mb-3'
                        size='2xl'
                    />
                    <h5 className='mb-2'>Storage</h5>
                    <p className='sm mt-2'>Solid-state drive</p>
                </Col>
                <Col className='text-center'>
                    <FontAwesomeIcon
                        fixedWidth
                        icon={['light', 'keyboard']}
                        className='primary mb-3'
                        size='2xl'
                    />
                    <h5 className='mb-2'>Input</h5>
                    <p className='sm mt-2'>Touchscreen (recommended)</p>
                </Col>
            </Row>
        </Container>
    );
};

export default SystemRequirementsGrid;
