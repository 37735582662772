import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const TestOperatorsRole = (props) => {
    return (
        <>
            <p>
                When administering a Conity Motion Evaluation, remember that your job is to guide
                the participant through the standardized procedure that is built into the software.
            </p>
            <Container className='px-0'>
                <Row>
                    <Col lg='6'>
                        <p className='mt-0'>
                            You should be focused on making sure the participant is safe and that
                            they clearly understand the provided instruction. It is important that
                            the evaluation is performed the same way by different operators, so
                            stick to the instructions provided in this training course and in the
                            software and use existing language to address any questions that arise.
                        </p>
                    </Col>
                    <Col lg='6'>
                        <img
                            src='/images/knowledge_center/test-operators-role-1.png'
                            alt='The test operator stands a safe distance away from the subject'
                        />
                    </Col>
                </Row>
            </Container>

            <p>
                Note that some participants may feel like they cannot perform some of the motions
                because the instructional videos intentionally display fast movements. In this case,
                emphasize that the videos are for reference only, and that the participant should
                move as best as they can within their comfort zone. Even the smallest amount of
                motion is okay, and as such, all participants should generally be able to complete
                all motions even if they feel like they are not moving much.
            </p>
            <div className='warning warning-left'>
                <p>
                    <strong>CAUTION:</strong> Do not coach or encourage participants to try to move
                    farther or faster than they feel comfortable, as doing so may result in injury.
                    Motions should not cause more discomfort than typical activities of daily living
                </p>
            </div>
        </>
    );
};

export default TestOperatorsRole;
