import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Pages } from '../lib/Pages';

const ResourcesSecurity = (props) => {
    return (
        <>
            <div className='masthead'>
                <Container className='content pt-5'>
                    <Row className='justify-content-center align-items-center'>
                        <Col lg='6'>
                            <h1>Waiting List</h1>
                            <p className='lg mb-5'>
                                Say why you'd want to run it locally instead of through a browser.
                                Lorem ipsum dolor sit amet.
                            </p>
                        </Col>
                        <Col lg='6'>
                            <img
                                src='/images/downloads-screenshot.png'
                                alt=''
                                className='ss-shadow'
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='content-section'>
                <Container>
                    <Row>
                        <Col md='6' className='mb-5 mb-md-0'>
                            <div className='icon-left'>
                                <div className='icon-container'>
                                    <img src='/images/icon-conity-desktop.png' alt='' />
                                </div>
                                <div className='icon-content'>
                                    <h2 className='mt-0 mb-2 h3'>Conity Desktop v2.1</h2>
                                    <p className='mt-2'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Pellentesque eu nisl laoreet, dapibus lorem ut, semper
                                        massa.
                                    </p>
                                    <ul className='list-checkmarks'>
                                        <li>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.{' '}
                                        </li>
                                        <li>
                                            Pellentesque eu nisl laoreet, dapibus lorem ut, semper.
                                        </li>
                                        <li>Design and execute studies of all types.</li>
                                    </ul>
                                    <p>
                                        <a href={Pages.RESOURCES_DOWNLOADS_CHANGELOG.path}>
                                            See What's New
                                        </a>
                                    </p>
                                    <Button variant='primary' href='#'>
                                        {' '}
                                        <FontAwesomeIcon
                                            fixedWidth
                                            icon={['light', 'download']}
                                            size='lg'
                                            className='me-2'
                                        />
                                        Download Conity Desktop
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col md='6'>
                            <div className='icon-left'>
                                <div className='icon-container'>
                                    <img src='/images/icon-conity-beta.png' alt='' />
                                </div>
                                <div className='icon-content'>
                                    <h2 className='mt-0 mb-2 h3'>Conity Beta v2.5</h2>
                                    <p className='mt-2'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Pellentesque eu nisl laoreet, dapibus lorem ut, semper
                                        massa.
                                    </p>
                                    <ul className='list-checkmarks'>
                                        <li>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.{' '}
                                        </li>
                                        <li>
                                            Pellentesque eu nisl laoreet, dapibus lorem ut, semper.
                                        </li>
                                        <li>Design and execute studies of all types.</li>
                                    </ul>
                                    <p>
                                        <a href={Pages.RESOURCES_DOWNLOADS_CHANGELOG.path}>
                                            See What's New
                                        </a>
                                    </p>
                                    <Button variant='primary' href='#'>
                                        {' '}
                                        <FontAwesomeIcon
                                            fixedWidth
                                            icon={['light', 'download']}
                                            size='lg'
                                            className='me-2'
                                        />
                                        Download Latest Stable Beta
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='content-section bg-light'>
                <Container>
                    <Row>
                        <Col className='text-center mb-5 mb-md-0' md='4'>
                            <FontAwesomeIcon
                                fixedWidth
                                icon={['light', 'signal-slash']}
                                size='3x'
                                className='primary mb-4'
                            />
                            <h4>Work Offline</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Pellentesque eu nisl laoreet, dapibus lorem ut, semper massa.
                            </p>
                        </Col>
                        <Col className='text-center mb-5 mb-md-0' md='4'>
                            <FontAwesomeIcon
                                fixedWidth
                                icon={['light', 'camera-cctv']}
                                size='3x'
                                className='primary mb-4'
                            />
                            <h4>Enhanced Security Controls</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Pellentesque eu nisl laoreet, dapibus lorem ut, semper massa.
                            </p>
                        </Col>
                        <Col className='text-center' md='4'>
                            <FontAwesomeIcon
                                fixedWidth
                                icon={['light', 'lambda']}
                                size='3x'
                                className='primary mb-4'
                            />
                            <h4>Maybe Another Feature</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Pellentesque eu nisl laoreet, dapibus lorem ut, semper massa.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* <WaitingList /> */}
        </>
    );
};

export default ResourcesSecurity;
