import React from 'react';

const SignIn = (props) => {
    return (
        <>
            <p>
                Once your account is created, you can login to Conity Desktop or Conity Browser
                using your email address. For some Organizations, Users will be able to use Single
                Sign-On (SSO). If SSO is not available for your Organization, you will be asked to
                create an account and password to finish account setup the first time you sign in to
                Conity.
            </p>
        </>
    );
};

export default SignIn;
