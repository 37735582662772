import { useState } from 'react';
import MegaMenu from './MegaMenu';
import PrimeNav from './PrimeNav';

const Header = (props) => {
    const [megaMenuOpen, setMegaMenuOpen] = useState(false);

    const onToggleMegaMenu = () => {
        setMegaMenuOpen(!megaMenuOpen);
    };

    const isKnockout = props.knockout ? 'knockout' : '';

    return (
        <div id='header' className={isKnockout}>
            <PrimeNav
                knockout={props.knockout}
                megaMenuOpen={megaMenuOpen}
                onToggleMegaMenu={onToggleMegaMenu}
            />

            <MegaMenu open={megaMenuOpen} onToggleMegaMenu={onToggleMegaMenu} />
        </div>
    );
};

export default Header;
