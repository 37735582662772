import { Col, Container, Row } from 'react-bootstrap';
import { Pages } from '../lib/Pages';

const Footer = (props) => {
    return (
        <footer>
            <Container>
                <Row className='py-5 d-flex justify-content-between'>
                    <Col sm='5' className='mb-5 mb-md-0 footer-logo'>
                        <img src='/images/logo-footer.png' alt='Conity' className='mb-5' />
                        <div className='footer-osu'>
                            <img
                                src='/images/the-ohio-state-university-horizontal.png'
                                alt='The Ohio State University'
                                className='mb-3'
                            />
                            <p className='mb-0'>
                                Conity is built by The Ohio State University Spine Research
                                Institute and is rooted in over 40 years of foundational federal and
                                state funded research.
                            </p>
                        </div>
                    </Col>
                    <Col className='footer-links'>
                        <a href={Pages.RESOURCES_DOWNLOADS.path}>Downloads</a>
                        <a href={Pages.RESOURCES_KNOWLEDGE_CENTER.path}>Knowledge Center</a>
                        <a href={Pages.PRIVACY.path}>Privacy</a>
                        {/* <a href={Pages.RESOURCES_SUPPORT.path}>Support</a>
                        <a href={Pages.RESOURCES_SECURITY.path}>Security</a> */}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='legal'>
                            <div>
                                <span className='copy'>
                                    &copy;{new Date().getFullYear()} The Ohio State University
                                </span>
                                {/* <a href={Pages.TERMS.path}>Terms</a>
                                <a href={Pages.PRIVACY.path}>Privacy</a> */}
                            </div>
                            <div className='fda'>
                                CAUTION - Investigational Device. Limited by United States law to
                                investigational use.
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
