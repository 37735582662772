import React from 'react';

const EvaluationOverview = (props) => {
    return (
        <>
            <p>
                Note that the procedures and instructions for the Low Back and Neck Motion
                Evaluations are generally the same unless specified in this training course.
            </p>
            <p>
                To conduct either evaluation, you will follow the step-by-step instructions built
                into the software. The steps will require you to...
            </p>
            <ol>
                <li>play an introduction video for the participant</li>
                <li>connect two sensors to the software through a wireless receiver</li>
                <li>place and adjust the sensors on the participant using harnesses</li>
                <li>
                    guide the participant through instructions for each of the specified motions
                </li>
                <li>record each motion</li>
                <li>confirm data quality with help from automated software checks</li>
                <li>submit collected data for analysis</li>
            </ol>
            <img
                src='/images/knowledge_center/evaluation-overview.png'
                alt='A female data collector adjusts the back harness of a male patient.'
            />
        </>
    );
};

export default EvaluationOverview;
