import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
    Button,
    Col,
    Container,
    Row,
    Accordion,
    Tabs,
    Tab,
    OverlayTrigger,
    Popover,
} from 'react-bootstrap';
import WaitingList from '../components/WaitingList';

const Home = (props) => {
    return (
        <>
            <div className='hero'>
                <div className='hero-interior'>
                    <Container className='content pt-md-5'>
                        <Row className='justify-content-center'>
                            <Col lg='8' md='10'>
                                <h1>Personalized Medicine at Your Fingertips</h1>
                                <p className='lg mb-5'>
                                    Employing modern technologies to help decode low back and neck
                                    pain
                                </p>
                                <p className='mb-md-5'>
                                    <Button variant='primary' size='lg' href='#osu'>
                                        Learn More
                                    </Button>
                                </p>
                            </Col>
                        </Row>
                    </Container>
                    <div className='screenshot'>
                        <img src='/images/hero-screenshot.png' />
                    </div>
                </div>
            </div>

            <div className='content-section' id='osu'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg='6' md='8' sm='10' className='text-center pt-5 pb-5'>
                            <img
                                src='/images/the-ohio-state-university-horizontal.png'
                                type='image/svg+xml'
                                className='mb-5'
                                alt='The Ohio State University'
                            />
                            <h2 className='pt-2'>Backed By Science</h2>
                            <p>
                                Conity is built by The Ohio State University Spine Research
                                Institute (SRI) and is rooted in over 40 years of foundational
                                federal and state funded research.
                            </p>
                            <Button variant='primary' href='//spine.osu.edu' target='_blank'>
                                Learn More About SRI
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='content-section gradient-light-bottom' id='uses'>
                <Container>
                    <Row className='justify-content-center mb-5'>
                        <Col lg='7' md='9' className='text-center'>
                            <FontAwesomeIcon
                                fixedWidth
                                icon={['thin', 'brain-circuit']}
                                size='4x'
                                className='primary'
                            />
                            <h2>Your data, at your service</h2>
                            <p className='lg-alt'>
                                Conity offers Insights as a Service (IaaS) to help your organization
                                unlock the secrets hiding in your data.
                            </p>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col lg='4' md='6' className='text-center mb-5 mb-md-0'>
                            <h4>Research &amp; Discovery</h4>
                            <p>
                                Design collaborative studies, accumulate quality data, and generate
                                insights 5x faster with our end-to-end platform.
                            </p>
                            {/* <Button variant='primary'>Start Exploring</Button> */}
                        </Col>
                        <Col lg={{ span: 4, offset: 1 }} md='6' className='text-center'>
                            {/* Avoid "care, treatment, decision making, etc." */}
                            <h4>FDA Breakthrough Device</h4>
                            <p>
                                Conity is an investigational device and has received Breakthrough
                                Device Designation from the FDA.
                            </p>

                            {/* <Button variant='primary'>See the Future</Button> */}
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='content-section'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg='5'>
                            <h2>
                                <small>Holistic Suite of</small> Digital Biomarkers
                            </h2>
                            <p>
                                Access a holistic suite of digital biomarkers derived from multiple
                                data sources in one platform, and compare results relative to large
                                normative databases.
                            </p>
                            {/* <Button variant='primary'>Learn More</Button> */}

                            <Accordion defaultActiveKey='0' flush>
                                <Accordion.Item eventKey='0'>
                                    <Accordion.Header>
                                        <div className='accordion-icon'>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={['light', 'wave-sine']}
                                                className='primary'
                                            />
                                        </div>
                                        Conity Motion Evaluation
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Gather insights into a patient's spine condition with our
                                        unique 10-minute evaluation of low back or neck function.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='1'>
                                    <Accordion.Header>
                                        <div className='accordion-icon'>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={['light', 'list-check']}
                                                className='primary'
                                            />
                                        </div>
                                        Patient Reported Outcomes
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Capture a diverse array of patient reported outcomes through
                                        a built-in library of 100+ validated questionnaires that can
                                        be sent directly to patients.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='2'>
                                    <Accordion.Header>
                                        <div className='accordion-icon'>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={['light', 'aperture']}
                                                className='primary'
                                            />
                                        </div>
                                        Biomedical Imaging
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Store and access biomedical imaging data such as CT and MRI.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='3'>
                                    <Accordion.Header>
                                        <div className='accordion-icon'>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={['light', 'display-medical']}
                                                className='primary'
                                            />
                                        </div>
                                        Electronic Health Records
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Document discrete diagnosis, treatment, and test result data
                                        taken from Electronic Health Records.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col lg={{ span: 6, offset: 1 }} className='pt-md-5'>
                            <img
                                src='/images/motion-sensor-fitment.jpg'
                                className='photo photo-shadow-right mt-4'
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='content-section gradient-light-top'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg='6' md='8' sm='10' className='text-center'>
                            <h2>
                                <small>One Platform For</small>Many Roles
                            </h2>
                            <p>
                                Low back and neck pain are ubiquitous, and though roles vary
                                tremendously, everyone needs better data to make informed decisions.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Tabs>
                                <Tab
                                    eventKey='researchers'
                                    title={
                                        <>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={['light', 'books-medical']}
                                                className='primary'
                                                size='xl'
                                            />
                                            Researchers{' '}
                                        </>
                                    }
                                >
                                    <Container>
                                        <Row className='justify-content-center'>
                                            <Col sm='5'>
                                                <h2>For Researchers</h2>
                                                <p>
                                                    Ditch the spreadsheets, improve quality,
                                                    collaborate seamlessly, and increase your
                                                    throughput by 5x.
                                                </p>
                                                <ul className='list-checkmarks'>
                                                    <li>
                                                        Collaborate seamlessly within and across
                                                        organizations
                                                    </li>
                                                    <li>Design and execute studies of all types</li>
                                                    <li>
                                                        Track study progress and manage research
                                                        operations
                                                    </li>
                                                    <li>
                                                        Test hypotheses in real-time with custom
                                                        visual dashboards
                                                    </li>
                                                    <li>
                                                        Filter, sort, and export data in multiple
                                                        formats
                                                    </li>
                                                    <li>
                                                        Support 21 CFR Part 11 compliant workflows
                                                        when collecting data for FDA submissions
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col sm={{ span: 6, offset: 1 }}>
                                                <img
                                                    src='/images/dashboard.png'
                                                    className='photo photo-shadow-right mt-4'
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab>
                                <Tab
                                    eventKey='providers'
                                    title={
                                        <>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={['light', 'user-doctor-hair-long']}
                                                className='primary'
                                                size='xl'
                                            />
                                            Providers{' '}
                                        </>
                                    }
                                >
                                    <Container>
                                        <Row className='justify-content-center'>
                                            <Col sm='5'>
                                                <h2>For Providers</h2>
                                                <p>
                                                    Quantify impairment, track patient progress, and
                                                    evaluate treatment effectiveness.
                                                </p>
                                                <ul className='list-checkmarks'>
                                                    <li>
                                                        Access a diverse array of novel, objective
                                                        biomarkers
                                                    </li>
                                                    <li>
                                                        Compare individual results to normative
                                                        populations
                                                    </li>
                                                    <li>Monitor patient progress over time</li>
                                                    <li>
                                                        Assess the effect of various treatments on
                                                        outcomes
                                                    </li>
                                                    <li>Streamline clinical research activities</li>
                                                    <li>
                                                        Collaborate with colleagues through
                                                        intuitive, visual reports
                                                    </li>
                                                </ul>
                                                <p style={{ paddingTop: '20px' }}>
                                                    <small>
                                                        <b>CAUTION:</b> Investigational Device.
                                                        Limited by United States law to
                                                        investigational use.
                                                    </small>
                                                </p>
                                            </Col>
                                            <Col sm={{ span: 6, offset: 1 }}>
                                                <img
                                                    src='/images/patient-and-doctor.jpg'
                                                    className='photo photo-shadow-right mt-4'
                                                    alt='Doctor checking joints of female patient'
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab>
                                <Tab
                                    eventKey='employers'
                                    title={
                                        <>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={['light', 'building-user']}
                                                className='primary'
                                                size='xl'
                                            />
                                            Employers{' '}
                                        </>
                                    }
                                >
                                    <Container>
                                        <Row className='justify-content-center'>
                                            <Col sm='5'>
                                                <h2>For Employers</h2>
                                                <p>
                                                    Assess workplace injury risk and help prevent
                                                    injuries.
                                                </p>
                                                <ul className='list-checkmarks'>
                                                    <li>
                                                        Assess occupational injury risk for your
                                                        workforce
                                                    </li>
                                                    <li>
                                                        Evaluate the effectiveness of workplace
                                                        interventions
                                                    </li>
                                                    <li>Benchmark employee spine health</li>
                                                    <li>Help decrease injuries</li>
                                                    <li>Inform safe return-to-work decisions</li>
                                                    <li>
                                                        Evaluate ROI for safety and ergonomics
                                                        investments
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col sm={{ span: 6, offset: 1 }}>
                                                <img
                                                    src='/images/construction-workers.jpg'
                                                    className='photo photo-shadow-right mt-4'
                                                    alt='A Man and Woman Wearing Hard Hat While Talking'
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab>
                                <Tab
                                    eventKey='military'
                                    title={
                                        <>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={['light', 'shield-blank']}
                                                className='primary'
                                                size='xl'
                                            />
                                            Military{' '}
                                        </>
                                    }
                                >
                                    <Container>
                                        <Row className='justify-content-center'>
                                            <Col sm='5'>
                                                <h2>For Military</h2>
                                                <p>
                                                    Optimize mission readiness through tools
                                                    optimized specifically for
                                                    military-representative populations.
                                                </p>
                                                <ul className='list-checkmarks'>
                                                    <li>
                                                        Track changes to warfighter health over time
                                                    </li>
                                                    <li>
                                                        Identify at-risk personnel to help prevent
                                                        injuries
                                                    </li>
                                                    <li>
                                                        Evaluate effectiveness of training programs
                                                        and equipment
                                                    </li>
                                                    <li>
                                                        Leverage military-specific reference
                                                        databases
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col sm={{ span: 6, offset: 1 }}>
                                                <img
                                                    src='/images/soldiers.jpg'
                                                    className='photo photo-shadow-right mt-4'
                                                    alt='Man in Green and Brown Camouflage Uniform Holding Black Rifle'
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='content-section gradient-light-top'>
                <Container>
                    <Row className='justify-content-center mb-5'>
                        <Col lg='8' md='10' className='text-center'>
                            <h2>
                                <small>In the United States</small>Spine Disorder Impact
                            </h2>
                            <p>
                                Chronic low back and neck pain are bigger problems than you may
                                think.
                            </p>
                            {/* <Button variant='primary'>Learn More</Button> */}
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col md='3' sm='4' className='mb-5'>
                            <OverlayTrigger
                                rootClose
                                trigger='click'
                                key='note-1'
                                placement='top'
                                overlay={
                                    <Popover id='cite-note-1'>
                                        <Popover.Body>
                                            Hartvigsen J, Hancock MJ, Kongsted A, et al. What low
                                            back pain is and why we need to pay attention. Lancet.
                                            2018;391(10137):2356-2367.
                                            <br />
                                            <br />
                                            Dieleman JL, Cao J, Chapin A, et al. US Health Care
                                            Spending by Payer and Health Condition, 1996-2016.
                                            Jama-J Am Med Assoc. 2020;323(9):863-884.
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <div className='counter'>
                                    <div className='counter-value'>540M</div>
                                    <div className='counter-label'>
                                        Lives Affected Globally
                                        <small className='cite-link'>*</small>
                                    </div>
                                    <div className='counter-sublabel'>
                                        More than diabetes + heart disease combined
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </Col>
                        <Col md='3' sm='4' className='mb-5'>
                            <OverlayTrigger
                                rootClose
                                trigger='click'
                                key='note-1'
                                placement='top'
                                overlay={
                                    <Popover id='cite-note-1'>
                                        <Popover.Body>
                                            Hoy D, March L, Brooks P, et al. The global burden of
                                            low back pain: estimates from the Global Burden of
                                            Disease 2010 study. Ann Rheum Dis. 2014;73(6):968-974.
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <div className='counter'>
                                    <div className='counter-value'>#1</div>
                                    <div className='counter-label'>
                                        Cause of Disability<small className='cite-link'>*</small>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </Col>
                        <Col md='3' sm='4' className='mb-5'>
                            <OverlayTrigger
                                rootClose
                                trigger='click'
                                key='note-1'
                                placement='top'
                                overlay={
                                    <Popover id='cite-note-1'>
                                        <Popover.Body>
                                            Fritz JM, King JB, McAdams-Marx C. Associations Between
                                            Early Care Decisions and the Risk for Long-term Opioid
                                            Use for Patients With Low Back Pain With a New Physician
                                            Consultation and Initiation of Opioid Therapy. Clinical
                                            Journal of Pain. 2018;34(6):552-558.
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <div className='counter'>
                                    <div className='counter-value'>#2</div>
                                    <div className='counter-label'>
                                        Reason Opiods are Prescribed
                                        <small className='cite-link'>*</small>
                                    </div>
                                    <div className='counter-sublabel'>
                                        Opiods contribute to 90,000 deaths/year
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </Col>
                        <Col md='3' sm='4' className='mb-5'>
                            <OverlayTrigger
                                rootClose
                                trigger='click'
                                key='note-1'
                                placement='top'
                                overlay={
                                    <Popover id='cite-note-1'>
                                        <Popover.Body>
                                            Dieleman JL, Cao J, Chapin A, et al. US Health Care
                                            Spending by Payer and Health Condition, 1996-2016.
                                            Jama-J Am Med Assoc. 2020;323(9):863-884.
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <div className='counter'>
                                    <div className='counter-value'>$134.5B</div>
                                    <div className='counter-label'>
                                        Direct Costs<small className='cite-link'>*</small>
                                    </div>
                                    <div className='counter-sublabel'>
                                        Similar to diabetes, heart disease, and cancer
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </Col>
                        <Col md='3' sm='4' className='mb-5'>
                            <OverlayTrigger
                                rootClose
                                trigger='click'
                                key='note-1'
                                placement='top'
                                overlay={
                                    <Popover id='cite-note-1'>
                                        <Popover.Body>
                                            Dieleman JL, Baral R, Birger M, et al. US Spending on
                                            Personal Health Care and Public Health, 1996-2013.
                                            Jama-J Am Med Assoc. 2016;316(24):2627-2646.
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <div className='counter'>
                                    <div className='counter-value'>300%</div>
                                    <div className='counter-label'>
                                        Increase in Cost Over 20 Years
                                        <small className='cite-link'>*</small>
                                    </div>
                                    <div className='counter-sublabel'>
                                        With no improvement in outcomes
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </Col>
                        <Col md='3' sm='4' className='mb-5'>
                            <OverlayTrigger
                                rootClose
                                trigger='click'
                                key='note-1'
                                placement='top'
                                overlay={
                                    <Popover id='cite-note-1'>
                                        <Popover.Body>
                                            Maher C, Underwood M, Buchbinder R. Non-specific low
                                            back pain. Lancet. 2017;389(10070):736-747.
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <div className='counter'>
                                    <div className='counter-value'>90%</div>
                                    <div className='counter-label'>
                                        Of Cases Do Not Have Clear Diagnoses
                                        <small className='cite-link'>*</small>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </Col>
                        <Col md='3' sm='4' className='mb-5'>
                            <OverlayTrigger
                                rootClose
                                trigger='click'
                                key='note-1'
                                placement='top'
                                overlay={
                                    <Popover id='cite-note-1'>
                                        <Popover.Body>
                                            Goode AP, Freburger J, Carey T. Prevalence, practice
                                            patterns, and evidence for chronic neck pain. Arthritis
                                            Care Res (Hoboken). 2010;62(11):1594-1601.
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <div className='counter'>
                                    <div className='counter-value'>7 Years</div>
                                    <div className='counter-label'>
                                        Average Symptom Duration
                                        <small className='cite-link'>*</small>
                                    </div>
                                </div>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* <WaitingList /> */}
        </>
    );
};

export default Home;
