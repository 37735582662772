import Home from '../pages/Home';
import Privacy from '../pages/Privacy';
import ResourcesDownloads from '../pages/ResourcesDownloads';
import ResourcesDownloadsChangelog from '../pages/ResourcesDownloadsChangelog';
import ResourcesKnowledgeCenter from '../pages/ResourcesKnowledgeCenter';
import ResourcesSecurity from '../pages/ResourcesSecurity';
import ResourcesStore from '../pages/ResourcesStore';
import ResourcesSupport from '../pages/ResourcesSupport';
import ResourcesWaitingList from '../pages/ResourcesWaitingList';
import Terms from '../pages/Terms';

export const Pages = {
    HOME: { path: `/`, element: <Home /> },
    PRIVACY: { path: `/privacy`, element: <Privacy /> },
    RESOURCES_DOWNLOADS: { path: `/resources/downloads`, element: <ResourcesDownloads /> },
    RESOURCES_DOWNLOADS_CHANGELOG: {
        path: `/resources/downloads/changelog`,
        element: <ResourcesDownloadsChangelog />,
    },

    RESOURCES_KNOWLEDGE_CENTER: {
        path: `/resources/knowledge-center`,
        element: <ResourcesKnowledgeCenter />,
    },
    RESOURCES_KNOWLEDGE_CENTER_GROUP: {
        path: `/resources/knowledge-center/:groupId`,
        element: <ResourcesKnowledgeCenter />,
    },
    RESOURCES_KNOWLEDGE_CENTER_ARTICLE: {
        path: `/resources/knowledge-center/:groupId/:articleId`,
        element: <ResourcesKnowledgeCenter />,
    },

    RESOURCES_SECURITY: { path: `/resources/security`, element: <ResourcesSecurity /> },
    RESOURCES_STORE: { path: `/resources/store`, element: <ResourcesStore /> },
    RESOURCES_SUPPORT: { path: `/resources/support`, element: <ResourcesSupport /> },
    RESOURCES_WAITING_LIST: { path: `/resources/waiting-list`, element: <ResourcesWaitingList /> },

    TERMS: { path: `/terms`, element: <Terms /> },
};
