import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Container, Offcanvas, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../lib/Pages';
import PrimeNav from './PrimeNav';

const MegaMenu = (props) => {
    const navigate = useNavigate();

    const onToggleMegaMenu = () => {
        if (props.onToggleMegaMenu) {
            props.onToggleMegaMenu();
        }
    };

    return (
        <Offcanvas show={props.open} onHide={onToggleMegaMenu} placement='top'>
            <Offcanvas.Header>
                <PrimeNav megaMenuOpen={props.open} onToggleMegaMenu={onToggleMegaMenu} />
                <Button
                    className='d-sm-block d-md-none toggle-back me-4'
                    variant='light'
                    onClick={onToggleMegaMenu}
                >
                    <FontAwesomeIcon fixedWidth icon={['light', 'chevron-left']} />
                    Back
                </Button>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Container>
                    <Row>
                        <Col>
                            <div className='title'>Resources</div>
                        </Col>
                    </Row>
                    <Row className='links'>
                        <Col md='4'>
                            <a href={Pages.RESOURCES_DOWNLOADS.path} className='nav-link-block'>
                                <div className='nav-link-block-icon'>
                                    <FontAwesomeIcon
                                        fixedWidth
                                        icon={['light', 'download']}
                                        size='2x'
                                    />
                                </div>
                                <div className='nav-link-block-content'>
                                    <div className='nav-link-block-heading'>Downloads</div>
                                    <div className='nav-link-block-copy'>
                                        Download the latest Conity Desktop Application installer.
                                    </div>
                                </div>
                            </a>
                        </Col>
                        {/* <Col md='4'>
                            <a href={Pages.RESOURCES_SECURITY.path} className='nav-link-block'>
                                <div className='nav-link-block-icon'>
                                    <FontAwesomeIcon
                                        fixedWidth
                                        icon={['light', 'lock-keyhole']}
                                        size='2x'
                                    />
                                </div>
                                <div className='nav-link-block-content'>
                                    <div className='nav-link-block-heading'>Security</div>
                                    <div className='nav-link-block-copy'>
                                        Learn how Conity keeps your data secure.
                                    </div>
                                </div>
                            </a>
                        </Col> */}
                        {/* <Col md='4'>
                            <a href={Pages.RESOURCES_SUPPORT.path} className='nav-link-block'>
                                <div className='nav-link-block-icon'>
                                    <FontAwesomeIcon
                                        fixedWidth
                                        icon={['light', 'comments-question-check']}
                                        size='2x'
                                    />
                                </div>
                                <div className='nav-link-block-content'>
                                    <div className='nav-link-block-heading'>Support</div>
                                    <div className='nav-link-block-copy'>
                                        Contact customer support.
                                    </div>
                                </div>
                            </a>
                        </Col> */}

                        {/* <Col md='4'>
                            <a href={Pages.RESOURCES_STORE.path} className='nav-link-block'>
                                <div className='nav-link-block-icon'>
                                    <FontAwesomeIcon
                                        fixedWidth
                                        icon={['light', 'box-taped']}
                                        size='2x'
                                    />
                                </div>
                                <div className='nav-link-block-content'>
                                    <div className='nav-link-block-heading'>Store</div>
                                    <div className='nav-link-block-copy'>
                                        Order replacement parts for Conity Motion Kits.
                                    </div>
                                </div>
                            </a>
                        </Col> */}

                        <Col md='4'>
                            <a
                                href={Pages.RESOURCES_KNOWLEDGE_CENTER.path}
                                className='nav-link-block'
                            >
                                <div className='nav-link-block-icon'>
                                    <FontAwesomeIcon
                                        fixedWidth
                                        icon={['light', 'users-gear']}
                                        size='2x'
                                    />
                                </div>
                                <div className='nav-link-block-content'>
                                    <div className='nav-link-block-heading'>Knowledge Center</div>
                                    <div className='nav-link-block-copy'>
                                        Access training and knowledge resources to learn how to use
                                        Conity.
                                    </div>
                                </div>
                            </a>
                        </Col>
                        {/* <Col md='4'>
                            <a href={Pages.RESOURCES_WAITING_LIST.path} className='nav-link-block'>
                                <div className='nav-link-block-icon'>
                                    <FontAwesomeIcon
                                        fixedWidth
                                        icon={['light', 'clipboard-check']}
                                        size='2x'
                                    />
                                </div>
                                <div className='nav-link-block-content'>
                                    <div className='nav-link-block-heading'>Waiting List</div>
                                    <div className='nav-link-block-copy'>
                                        Join our waiting list and we will reach out when Conity
                                        becomes more broadly available.
                                    </div>
                                </div>
                            </a>
                        </Col> */}
                    </Row>
                </Container>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default MegaMenu;
