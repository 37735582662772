import * as Component from '../lib/Component';

const SmartHider = (props) => {
    let baseClassNames = ['smart-hider'];

    const classNameString = Component.getClassNameString({
        props,
        baseClassNames,
        keyPropNames: ['hideWhen', 'showWhen'],
        valuePropNames: ['hideWhen', 'showWhen', 'className'],
        colorPropNames: [],
    });

    return <div className={classNameString}>{props.children}</div>;
};

export default SmartHider;
