import React from 'react';
import { Col, Container, Row, Figure } from 'react-bootstrap';

const CoursePurpose = (props) => {
    return (
        <>
            <p>
                Welcome to the Conity Motion Evaluation training course for Operators. This course
                is designed to teach Operators how to conduct the Conity Low Back and Neck Motion
                Evaluations safely, correctly, and efficiently. To complete this course, you will
                need access to...
            </p>
            <ol>
                <li>A computer that has the Conity Desktop Application installed.</li>
                <li>A Conity Motion Kit.</li>
                <li>A partner who can act as the participant</li>
            </ol>
            <Container>
                <Row className='justify-content-around'>
                    <Col className='d-flex' lg='4'>
                        <Figure>
                            <Figure.Image src='/images/knowledge_center/app-icon.png' alt='' />
                            <Figure.Caption>Conity Desktop App</Figure.Caption>
                        </Figure>
                    </Col>
                    <Col className='d-flex' lg='4'>
                        <Figure>
                            <Figure.Image src='/images/knowledge_center/hardware-case.png' alt='' />
                            <Figure.Caption>Conity Motion Kit</Figure.Caption>
                        </Figure>
                    </Col>
                </Row>
            </Container>
            <p>
                As you work through the course, take time to practice each step to ensure you fully
                understand the information provided.
            </p>
            <p>
                If you have an extra computer available, it may be helpful to have this course open
                on one computer while you practice the motion evaluation on the other.
            </p>
        </>
    );
};

export default CoursePurpose;
