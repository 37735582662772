import React from 'react';

const CreatingSampleEvaluation = (props) => {
    return (
        <>
            <p>
                You will need to create a sample Motion Evaluation to use throughout the remainder
                of the course. To do this, follow the steps below
            </p>
            <h2>1) Select a project that includes a Motion Evaluation</h2>
            <p>
                On your Home Dashboard, select the project you want to use for training by clicking
                the "View Project" button. Note that you can use the dropdown arrow next to “View
                Project” to enter the project in “Test Mode” or can enter into test mode on the next
                step.
            </p>
            <img src='/images/knowledge_center/create-sample-1.png' alt='' />
            <h2>2) Enter into Test Mode</h2>
            <p>
                If you did not enter the project in "Test Mode" on the previous step, click on the
                project name in the scope navigator and click "Test this project" in the drawer that
                pops up. Note that the blue header at the top of the page will turn orange and
                include a message in the top right corner when you have successfully entered into
                Test Mode.
            </p>
            <img src='/images/knowledge_center/create-sample-2.png' alt='' />
            <h2>3) Create a FAKE participant</h2>
            <p>
                Click on the "Participants" tab in the primary navigation menu, and then click "New
                Participant". Note that "Participant" may appear as "Patient", "Subject",
                "Participant", "Athlete", or "Service Member" throughout the app depending on your
                project settings.
            </p>
            <img src='/images/knowledge_center/create-sample-3.png' alt='' />
            <p>
                Select the participant group and series you would like to use for testing, and then
                fill out the required participant information to create the participant. Note that
                all participants created in Test Mode will be marked as "FAKE" and will be kept
                separate from real data.
            </p>
            <h2>4) Select the sample Motion Evaluation</h2>
            <p>
                Select the motion evaluation you would like to use to practice and click "Select
                Motion Evaluation" to begin.
            </p>
            <img src='/images/knowledge_center/create-sample-4.png' alt='' />
        </>
    );
};

export default CreatingSampleEvaluation;
