import { Pages } from '../../lib/Pages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import * as KnowledgeCenter from '../../lib/KnowledgeCenter';
import { useState } from 'react';

// const handleShow = () => setShow(true);

const KnowledgeCenterNav = (props) => {
    const navigate = useNavigate();
    const articleGroups = KnowledgeCenter.ArticleGroups();

    console.log(articleGroups);

    const { groupId, articleId } = useParams();
    let activeGroup = articleGroups.find((group) => group.id === groupId);
    if (!activeGroup) {
        activeGroup = articleGroups[0];
    }
    let activeArticle = activeGroup.articles.find((article) => article.id === articleId);
    if (!activeArticle) {
        activeArticle = activeGroup.articles[0];
    }

    return (
        <nav>
            <ul className='kc-links-group'>
                {articleGroups.map((group, groupIndex) => (
                    <li key={groupIndex}>
                        <strong>
                            <FontAwesomeIcon icon={['light', group.iconName]} className='me-1' />{' '}
                            {group.header}
                        </strong>
                        <ul className='kc-links-group-links'>
                            {group.articles.map((article, articleIndex) => {
                                const active = article.header === activeArticle.header;

                                return (
                                    <li key={articleIndex}>
                                        <button
                                            onClick={() =>
                                                navigate(
                                                    `${Pages.RESOURCES_KNOWLEDGE_CENTER.path}/${group.id}/${article.id}`
                                                )
                                            }
                                            className={active ? 'current' : undefined}
                                        >
                                            {article.header}
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default KnowledgeCenterNav;
