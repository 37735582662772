import { Col, Container, Row } from 'react-bootstrap';
import SystemRequirementsGrid from '../components/SystemRequirementsGrid';
import InstallationInstructions from '../components/InstallationInstructions';

const ResourcesDownloads = (props) => {
    return (
        <>
            <div className='masthead pb-0'>
                <Container>
                    <Row className='justify-content-center mb-3'>
                        <Col lg='7' md='9' className='text-center'>
                            <img
                                src='/images/conity-desktop-icon.png'
                                alt=''
                                className='mb-4'
                                style={{ height: '80px' }}
                            />
                            <h1>Conity Desktop</h1>
                            <p className='lg'>
                                Include motion evaluations in your data collection workflows
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='content-section'>
                <InstallationInstructions />
            </div>
            {/* <div className='masthead'>
                <Container className='content pt-5'>
                    <Row className='justify-content-center align-items-center'>
                        <Col lg='6'>
                            <h1>
                                <small>Download</small> Conity Desktop
                            </h1>
                            <p className='lg mb-5'>
                                Include motion evaluations in your data collection workflows
                            </p>
                            {renderDownloadSection()}
                        </Col>
                        <Col lg='6'>
                            <img
                                src='/images/screenshot-motion.png'
                                alt=''
                                className='photo photo-shadow-right mt-4'
                            />
                        </Col>
                    </Row>
                </Container>
            </div> */}
            <div className='content-section gradient-light-top'>
                <SystemRequirementsGrid />
            </div>
        </>
    );
};

export default ResourcesDownloads;
