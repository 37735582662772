import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import {
    Navbar,
    Nav,
    NavDropdown,
    Form,
    Offcanvas,
    Button,
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import SmartHider from '../components/SmartHider';
import KnowledgeCenterNav from '../components/knowledge_center/KnowledgeCenterNav';
import { useNavigate, useParams } from 'react-router-dom';
import * as KnowledgeCenter from '../lib/KnowledgeCenter';

// import { Pages } from '../lib/Pages';
// import { SmartHider } from '../components/SmartHider';

const ResourcesKnowledgeCenter = (props) => {
    const navigate = useNavigate();
    const articleGroups = KnowledgeCenter.ArticleGroups();

    console.log(articleGroups);

    const { groupId, articleId } = useParams();
    let activeGroup = articleGroups.find((group) => group.id === groupId);
    if (!activeGroup) {
        activeGroup = articleGroups[0];
    }
    let activeArticle = activeGroup.articles.find((article) => article.id === articleId);
    if (!activeArticle) {
        activeArticle = activeGroup.articles[0];
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className='masthead'>
                <Container className='content pt-5'>
                    <Row>
                        <Col className='text-center'>
                            <h1>Knowledge Center</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <SmartHider showWhen='lgDown' className=' kc-sidebar-mobile-container'>
                <Container>
                    <Button
                        variant='outline-light'
                        className='kc-mobile-toggle'
                        onClick={handleShow}
                    >
                        Navigate <FontAwesomeIcon fixedWidth icon={['light', 'bars']} />
                    </Button>
                    <Offcanvas show={show} onHide={handleClose} className='kc-sidebar-nav-mobile'>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Knowledge Center</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body data-bs-dismiss='offcanvas'>
                            <KnowledgeCenterNav />
                        </Offcanvas.Body>
                    </Offcanvas>
                </Container>

                {/* 
                // Trying the mobile nav via another method. still can't get it to close on click of link.
                <Navbar key='kc' bg='light' expand={false} className='mb-3' collapseOnSelect>
                    <Container fluid>
                        <Navbar.Brand href='#'>Navbar Offcanvas</Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-kc`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-kc`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-kc`}
                            placement='end'
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-kc`}>
                                    Offcanvas
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <KnowledgeCenterNav />
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar> */}
            </SmartHider>
            <div className='content-section pt-0'>
                <Container>
                    <div className='kc-layout'>
                        <SmartHider hideWhen='lgDown'>
                            <aside className='kc-sidebar'>
                                <KnowledgeCenterNav />
                            </aside>
                        </SmartHider>
                        <main className='kc-main'>
                            <div className='kc-article-title'>{activeGroup.header}</div>
                            <h1>{activeArticle.header}</h1>
                            {activeArticle.component}
                        </main>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default ResourcesKnowledgeCenter;
