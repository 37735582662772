const LogoFull = (props) => {
    const fillColor1 = props.knockout ? '#FFFFFF' : '#0b3859';
    const fillColor2 = props.knockout ? '#FFFFFF' : '#1568B2';
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='224.491'
            height='53.003'
            viewBox='0 0 224.491 53.003'
        >
            <g id='Group_6505' data-name='Group 6505' transform='translate(-304 -20)'>
                <path
                    id='Path_464'
                    data-name='Path 464'
                    d='M336.011,8.423a3.69,3.69,0,0,1,3.663,3.663,3.615,3.615,0,0,1-7.228,0,3.671,3.671,0,0,1,3.565-3.663'
                    transform='translate(133.921 15.691)'
                    fill={fillColor1}
                />
                <path
                    id='Path_465'
                    data-name='Path 465'
                    d='M335.895,28.723a8.415,8.415,0,0,0-2.149.293V51.629c0,2.979,1.856,3.956,3.761,3.956a8.416,8.416,0,0,0,2.149-.293V32.679c0-2.979-1.856-3.956-3.761-3.956'
                    transform='translate(133.256 5.305)'
                    fill={fillColor1}
                />
                <path
                    id='Path_466'
                    data-name='Path 466'
                    d='M201.646,40.394c0-8.156,6.3-13.871,14.164-13.871a13.969,13.969,0,1,1-.1,27.937,13.682,13.682,0,0,1-14.066-14.066m22.222,0c0-5.812-4-9.133-8.156-9.133-4.249,0-8.059,3.321-8.059,9.133s3.956,9.231,8.107,9.231c4.3.049,8.107-3.37,8.107-9.231'
                    transform='translate(200.838 6.431)'
                    fill={fillColor1}
                />
                <path
                    id='Path_467'
                    data-name='Path 467'
                    d='M294.18,37.014a3.7,3.7,0,0,0-.049-.733v-.147c0-.2-.049-.391-.049-.586V35.4a12.016,12.016,0,0,0-.342-1.416,3.829,3.829,0,0,0-.244-.684v-.049c-1.709-4.4-6.4-7.131-11.233-7.131-5.421,0-10.549,3.37-11.722,8.645v.1c-.049.2-.049.391-.1.586V35.6c0,.2-.049.342-.049.537v.147c0,.244-.049.44-.049.684V49.42c0,2.54,1.319,3.614,2.882,3.907a5.382,5.382,0,0,0,.879.1,7.987,7.987,0,0,0,1.074-.1,10.1,10.1,0,0,0,1.074-.2V37.893c0-3.712,1.954-6.545,6.007-6.545,4.2,0,6.007,3.712,6.007,6.545V49.42c0,2.54,1.319,3.614,2.882,3.907a5.382,5.382,0,0,0,.879.1,7.987,7.987,0,0,0,1.074-.1,10.1,10.1,0,0,0,1.074-.2V37.014Z'
                    transform='translate(165.691 6.635)'
                    fill={fillColor1}
                />
                <path
                    id='Path_468'
                    data-name='Path 468'
                    d='M422.947,27.823l-8.156,17-8.449-17h-6.5L411.47,51.51l-4.884,9.963c-.1.2-.147.342-.244.537a3.24,3.24,0,0,0,.733,3.81,4.178,4.178,0,0,0,1.074.733,8.8,8.8,0,0,0,2,.684l18.9-39.414Z'
                    transform='translate(99.439 5.766)'
                    fill={fillColor1}
                />
                <path
                    id='Path_469'
                    data-name='Path 469'
                    d='M85.654,26.519a19.553,19.553,0,0,1,5.714,19.194,1.641,1.641,0,1,0,3.175.83A22.817,22.817,0,0,0,87.9,24.175a1.612,1.612,0,0,0-2.686,1.465,1.183,1.183,0,0,0,.44.879'
                    transform='translate(260.419 7.851)'
                    fill={fillColor2}
                />
                <path
                    id='Path_470'
                    data-name='Path 470'
                    d='M38.944,57.471a1.638,1.638,0,0,0-.586-3.223,19.573,19.573,0,0,1-6.886-38.535,19.08,19.08,0,0,1,8.938.488,1.624,1.624,0,1,0,.879-3.126,22.844,22.844,0,1,0-2.344,44.4'
                    transform='translate(297.85 13.773)'
                    fill={fillColor2}
                />
                <path
                    id='Path_471'
                    data-name='Path 471'
                    d='M2.367,48.535a1.656,1.656,0,0,0,1.319-1.9A25.4,25.4,0,0,1,7.1,28.706,1.633,1.633,0,0,0,4.32,27,28.571,28.571,0,0,0,.462,47.216a1.683,1.683,0,0,0,1.9,1.319'
                    transform='translate(304 6.592)'
                    fill={fillColor2}
                />
                <path
                    id='Path_472'
                    data-name='Path 472'
                    d='M29.908,5.53a1.631,1.631,0,0,0,2.2.684A25.513,25.513,0,0,1,64.438,44.065a1.641,1.641,0,0,0,2.637,1.954A28.78,28.78,0,0,0,38.993.451a28.252,28.252,0,0,0-8.352,2.882,1.572,1.572,0,0,0-.83,1.709,1.735,1.735,0,0,0,.1.488'
                    transform='translate(288.766 20)'
                    fill={fillColor2}
                />
                <path
                    id='Path_475'
                    data-name='Path 475'
                    d='M30.063,42.143a1.675,1.675,0,0,0-2.2.684,16.029,16.029,0,0,0,5.958,21.1.171.171,0,0,1,.1.049,1.527,1.527,0,0,0,.928.2,1.59,1.59,0,0,0,.684-.2,1.609,1.609,0,0,0,.586-2.2,1.829,1.829,0,0,0-.635-.586A12.831,12.831,0,0,1,30.7,44.34a1.573,1.573,0,0,0-.635-2.2'
                    transform='translate(290.663 -1.472)'
                    fill={fillColor2}
                />
                <path
                    id='Path_476'
                    data-name='Path 476'
                    d='M62.344,28.062A15.021,15.021,0,0,0,57.606,26.5a1.61,1.61,0,1,0-.537,3.175,11.422,11.422,0,0,1,3.761,1.27,12.772,12.772,0,0,1,1.954,21.49,13.1,13.1,0,0,1-8.449,2.637c-.244,0-.488-.049-.781-.049-.147,0-.293-.049-.488-.049a1.051,1.051,0,0,0-.391,0,2.2,2.2,0,0,0-.537.147c-.049,0-.1.049-.147.049a1.589,1.589,0,0,0-.733,1.9c.049.1.1.244.147.342a1.44,1.44,0,0,0,.684.635,3.153,3.153,0,0,0,.928.2A16.852,16.852,0,0,0,61.9,56.731a16.13,16.13,0,0,0,7.131-6.74,16.648,16.648,0,0,0,1.954-7.766,15.737,15.737,0,0,0-1.807-7.277,15.9,15.9,0,0,0-5.031-5.763,8.607,8.607,0,0,0-1.807-1.123'
                    transform='translate(277.82 6.454)'
                    fill={fillColor2}
                />
                <path
                    id='Path_477'
                    data-name='Path 477'
                    d='M159.293,49.429a8.755,8.755,0,0,1-8.889-8.84c0-5.47,4-9.133,8.645-9.133a8.6,8.6,0,0,1,5.177,1.758,3.036,3.036,0,0,0,3.907-.244l1.465-1.563c-.293-.342-.586-.635-.879-.928a14.042,14.042,0,0,0-10.11-3.956,13.745,13.745,0,0,0-14.164,14.115A13.765,13.765,0,0,0,158.61,54.46c7.521,0,10.94-4.054,11.038-4.2l-1.026-1.221a3.531,3.531,0,0,0-4.347-.879,11.235,11.235,0,0,1-4.982,1.27'
                    transform='translate(230.102 6.431)'
                    fill={fillColor1}
                />
                <path
                    id='Path_478'
                    data-name='Path 478'
                    d='M374.438,17.947H365.5V8.423h-5.958v9.524h-1.221c-2.393,0-3.175,1.465-3.175,3.028a6.878,6.878,0,0,0,.244,1.709h4.151V34.943c0,5.91,2.784,9.719,8.791,9.719a22.013,22.013,0,0,0,4.3-.537l-.2-4.737a9.68,9.68,0,0,1-2.589.342c-2.247,0-4.4-1.123-4.4-4.64V22.684h6.007a4.463,4.463,0,0,0,1.514-.244,2.742,2.742,0,0,0,1.661-2.735,4.343,4.343,0,0,0-.2-1.758'
                    transform='translate(122.307 15.691)'
                    fill={fillColor1}
                />
                <path
                    id='Path_846'
                    data-name='Path 846'
                    d='M0,0H224.491V53H0Z'
                    transform='translate(304 20)'
                    fill='none'
                />
                <path
                    id='Path_847'
                    data-name='Path 847'
                    d='M0,0H224.491V53H0Z'
                    transform='translate(304 20)'
                    fill='none'
                />
                <path
                    id='Path_473'
                    data-name='Path 473'
                    d='M45.446,81.323'
                    transform='translate(280.75 -21.605)'
                    fill={fillColor2}
                />
                <path
                    id='Path_474'
                    data-name='Path 474'
                    d='M45.446,81.323Z'
                    transform='translate(280.75 -21.605)'
                    fill='none'
                />
            </g>
        </svg>
    );
};

export default LogoFull;
