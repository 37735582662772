import React from 'react';

const EvaluationPurpose = (props) => {
    return (
        <>
            <p>
                During these evaluations, Conity motion sensors are used to measure an individual’s
                spine function during a series of standardized motions
            </p>
            <img
                src='/images/knowledge_center/evaluation-purpose.png'
                alt='A man with his back turned to the screen wearing a Conity chest harness and hips harness with sensors.'
            />
            <p>
                Measurements captured from the sensors are compared to patients with a variety of
                conditions, as well as people who are asymptomatic. This comparison helps provide
                context to an individual's specific condition.
            </p>
        </>
    );
};

export default EvaluationPurpose;
