import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import FontAwesomeConfig from './components/FontAwesomeConfig';
import Header from './components/Header';
import Footer from './components/Footer';
import './css/site.css';
import { Pages } from './lib/Pages';

// DO NOT DELETE - Loads all FontAwesome icons
const fontAwesomeConfig = new FontAwesomeConfig();

const App = () => {
    return (
        <BrowserRouter>
            <div>
                <Header knockout={window.location.pathname === '/'} />

                <Routes>
                    {Object.values(Pages).map((page) => (
                        <Route key={page.path} path={page.path} element={page.element} />
                    ))}
                </Routes>

                <Footer />
            </div>
        </BrowserRouter>
    );
};

export default App;
