import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

const InstallationInstructions = (props) => {
    const [betaInfo, setBetaInfo] = useState();
    const [prodInfo, setProdInfo] = useState();

    useEffect(() => {
        const betaBucketPath =
            'https://sp2-beta-desktop-application-releases.s3.us-east-2.amazonaws.com';
        fetch(`${betaBucketPath}/beta.yml`).then(async (response) => {
            const text = await response.text();
            const fileName = text.match(/path:[^\n]*(?=\n)/g)[0].replace('path: ', '');
            const betaUrl = `${betaBucketPath}/${fileName}`;
            const betaVer = text.match(/version:[^\n]*(?=\n)/g)[0].replace('version: ', '');

            setBetaInfo({
                version: betaVer,
                url: betaUrl,
            });
        });

        const prodBucketPath =
            'https://sp2-prod-desktop-application-releases.s3.us-east-2.amazonaws.com';
        fetch(`${prodBucketPath}/latest.yml`).then(async (response) => {
            const text = await response.text();
            const fileName = text.match(/path:[^\n]*(?=\n)/g)[0].replace('path: ', '');
            const prodUrl = `${prodBucketPath}/${fileName}`;
            const prodVer = text.match(/version:[^\n]*(?=\n)/g)[0].replace('version: ', '');

            setProdInfo({
                version: prodVer,
                url: prodUrl,
            });
        });
    }, []);

    const renderDownloadSection = () => {
        if (prodInfo) {
            return (
                <div className='download-section'>
                    <Button variant='primary' href={prodInfo.url} className='mb-4'>
                        <FontAwesomeIcon
                            fixedWidth
                            icon={['light', 'download']}
                            size='lg'
                            className='me-2'
                        />
                        {`Download ${prodInfo.version}`}
                    </Button>

                    <div className='mt-3 mb-4'>{renderBetaDownloadButton()}</div>
                </div>
            );
        }
    };

    const renderProdVersion = () => {
        if (prodInfo) {
            return <>{prodInfo.version}</>;
        }
    };

    const renderBetaDownloadButton = () => {
        if (betaInfo) {
            return <a href={betaInfo.url}>{`Download ${betaInfo.version} (Beta)`}</a>;
        }
    };
    return (
        <Container>
            <Row>
                <Col lg='4'>
                    <h2>
                        <small>Step 1</small> Download
                    </h2>
                    <p>
                        Once you've verified that your device meets the requirements, download
                        Conity Desktop application and run the installer as an admin.
                    </p>
                    {renderDownloadSection()}
                </Col>
                <Col lg='4'>
                    <h2>
                        <small>Step 2</small> Install
                    </h2>
                    <p>
                        Click on the installer file "Conity Desktop Setup v{renderProdVersion()}
                        .exe" to begin installation.
                    </p>
                    <p>
                        In the case that Windows requires additional action to permit the
                        installation, select "More info" then "Run anyway" in the Microsoft Defender
                        window.
                    </p>
                </Col>
                <Col lg='4'>
                    <h2>
                        <small>Step 3</small> Drivers
                    </h2>
                    <p>Supporting drivers must be manually installed.</p>
                    <ol>
                        <li>
                            Open File Explorer and navigate to{' '}
                            <span className='text-nowrap'>C:\Program Files\</span>
                            Conity Desktop\installers\xsens
                        </li>
                        <li>Double-click SetupDriver_x64.exe to run installer</li>
                        <li>
                            Walk through installer prompts and confirm that all drivers have green
                            check marks to indicate successful install
                        </li>
                    </ol>
                </Col>
            </Row>
        </Container>
    );
};

export default InstallationInstructions;
