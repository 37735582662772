import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import { Pages } from '../lib/Pages';
import LogoFull from './LogoFull.jsx';

const PrimeNav = (props) => {
    const resourcesIconName = props.megaMenuOpen ? 'xmark' : 'chevron-down';

    const host = window.location.host;

    const loginPath = host.startsWith('localhost') ? undefined : `https://app.${host}`;

    return (
        <>
            <Navbar expand='md' className='prime-nav'>
                <Container fluid>
                    <Navbar.Brand href={Pages.HOME.path}>
                        <LogoFull knockout={props.knockout} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls='basic-navbar-nav' />
                    <Navbar.Collapse>
                        <Nav className='ms-auto'>
                            <Nav.Link onClick={props.onToggleMegaMenu}>
                                Resources <FontAwesomeIcon icon={['light', resourcesIconName]} />
                            </Nav.Link>
                            <Button href={loginPath} className='header-login ms-md-4'>
                                Log In
                            </Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default PrimeNav;
