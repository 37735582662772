import { paramCase } from 'change-case';

export function getClassNameString(params) {
    if (params.props) {
        let classNames = [];

        // Base classes
        if (params.baseClassNames) {
            classNames.push(...params.baseClassNames);
        }

        // Classes from key props
        if (params?.keyPropNames) {
            params.keyPropNames.forEach((e) => {
                if (params.props[e]) {
                    classNames.push(paramCase(e));
                }
            });
        }

        // Classes from value props
        if (params?.valuePropNames) {
            params.valuePropNames.forEach((e) => {
                if (params.props[e]) {
                    classNames.push(params.props[e]);
                }
            });
        }

        // Classes from color props
        // Note --- keeping this here even though it is currently same as value props since it will likely change in the future to accomodate multiple color props
        if (params?.colorPropNames) {
            params.colorPropNames.forEach((e) => {
                if (params.props[e]) {
                    classNames.push(params.props[e]);
                }
            });
        }

        return classNames.join(' ');
    } else {
        return '';
    }
}
