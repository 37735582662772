import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ResourcesDownloadsChangelog = (props) => {
    const navigate = useNavigate();
    return (
        <>
            <div className='masthead'>
                <Container className='content pt-5'>
                    <Row>
                        <Col className='text-center'>
                            <h1>Changelog</h1>
                            <Button variant='primary' href='#'>
                                {' '}
                                <FontAwesomeIcon
                                    fixedWidth
                                    icon={['light', 'download']}
                                    size='lg'
                                    className='me-2'
                                />
                                Download Latest Stable Version
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='content-section pt-0'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg='7' md='9'>
                            <div className='changelog-version'>
                                <h2>
                                    v2.1<span>May 9, 2022</span>
                                </h2>
                                <h3>New</h3>
                                <ul>
                                    <li>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    </li>
                                    <li>
                                        Etiam ac erat quis mauris sollicitudin dictum sed eget
                                        mauris.
                                    </li>
                                    <li>
                                        Integer in lacus malesuada, egestas nisi et, convallis nisl.
                                    </li>
                                    <li>Ut ultrices neque nec nisl sagittis aliquet.</li>
                                    <li>
                                        Vestibulum ac leo sollicitudin, malesuada sapien a, suscipit
                                        risus.
                                    </li>
                                </ul>
                                <h3>Improved</h3>
                                <ul>
                                    <li>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    </li>
                                    <li>
                                        Etiam ac erat quis mauris sollicitudin dictum sed eget
                                        mauris.
                                    </li>
                                    <li>
                                        Integer in lacus malesuada, egestas nisi et, convallis nisl.
                                    </li>
                                    <li>Ut ultrices neque nec nisl sagittis aliquet.</li>
                                    <li>
                                        Vestibulum ac leo sollicitudin, malesuada sapien a, suscipit
                                        risus.
                                    </li>
                                </ul>
                                <h3>Fixed</h3>
                                <ul>
                                    <li>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    </li>
                                    <li>
                                        Etiam ac erat quis mauris sollicitudin dictum sed eget
                                        mauris.
                                    </li>
                                    <li>
                                        Integer in lacus malesuada, egestas nisi et, convallis nisl.
                                    </li>
                                    <li>Ut ultrices neque nec nisl sagittis aliquet.</li>
                                    <li>
                                        Vestibulum ac leo sollicitudin, malesuada sapien a, suscipit
                                        risus.
                                    </li>
                                </ul>
                            </div>
                            <div className='changelog-version'>
                                <h2>
                                    v2.0<span>April 2, 2022</span>
                                </h2>
                                <h3>New</h3>
                                <ul>
                                    <li>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    </li>
                                    <li>
                                        Etiam ac erat quis mauris sollicitudin dictum sed eget
                                        mauris.
                                    </li>
                                    <li>
                                        Integer in lacus malesuada, egestas nisi et, convallis nisl.
                                    </li>
                                    <li>Ut ultrices neque nec nisl sagittis aliquet.</li>
                                    <li>
                                        Vestibulum ac leo sollicitudin, malesuada sapien a, suscipit
                                        risus.
                                    </li>
                                </ul>
                                <h3>Improved</h3>
                                <ul>
                                    <li>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    </li>
                                    <li>
                                        Etiam ac erat quis mauris sollicitudin dictum sed eget
                                        mauris.
                                    </li>
                                    <li>
                                        Integer in lacus malesuada, egestas nisi et, convallis nisl.
                                    </li>
                                    <li>Ut ultrices neque nec nisl sagittis aliquet.</li>
                                    <li>
                                        Vestibulum ac leo sollicitudin, malesuada sapien a, suscipit
                                        risus.
                                    </li>
                                </ul>
                                <h3>Fixed</h3>
                                <ul>
                                    <li>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    </li>
                                    <li>
                                        Etiam ac erat quis mauris sollicitudin dictum sed eget
                                        mauris.
                                    </li>
                                    <li>
                                        Integer in lacus malesuada, egestas nisi et, convallis nisl.
                                    </li>
                                    <li>Ut ultrices neque nec nisl sagittis aliquet.</li>
                                    <li>
                                        Vestibulum ac leo sollicitudin, malesuada sapien a, suscipit
                                        risus.
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* <WaitingList /> */}
        </>
    );
};

export default ResourcesDownloadsChangelog;
