import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Button, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ResourcesSecurity = (props) => {
    const navigate = useNavigate();
    return (
        <>
            <div className='masthead pb-0'>
                <Container>
                    <Row className='justify-content-center mb-5'>
                        <Col lg='7' md='9' className='text-center'>
                            <FontAwesomeIcon
                                fixedWidth
                                icon={['thin', 'lock-keyhole']}
                                size='4x'
                                className='primary mb-4'
                            />
                            <h1>Security</h1>
                            <p className='lg'>
                                It's so secure you can be secure in the security that it's so
                                secure. Lorem ipsum dolor sit amet.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='content-section'>
                <Container>
                    <Row>
                        <Col md='5'>
                            <img
                                src='/images/photo-placeholder.png'
                                className='photo photo-shadow-right mt-4'
                            />
                        </Col>
                        <Col md={{ span: 6, offset: 1 }}>
                            <h2>
                                <small>Lorem Ipsum</small> Lorem Ipsum Dolor
                            </h2>
                            <p>
                                Etiam vel nibh eget lorem semper consequat eget sed ex. Duis risus
                                metus, vulputate ultricies est id, aliquam dignissim diam.{' '}
                            </p>
                            <p>
                                Class aptent taciti sociosqu ad litora torquent per conubia nostra,
                                per inceptos himenaeos. Aenean dictum urna nec massa venenatis, id
                                condimentum nisi volutpat. Quisque sit amet consectetur nunc.
                                Aliquam quis purus vitae turpis vehicula bibendum.{' '}
                            </p>
                            <p>
                                Proin quis mauris id urna lacinia dignissim. Integer ac ultrices
                                tortor, ac ullamcorper nulla. Vestibulum eget porttitor turpis. Sed
                                consequat porta molestie.{' '}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='content-section'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg='5'>
                            <h2>
                                <small>Lorem ipsum</small> Security Features
                            </h2>
                            <p>
                                Cras vitae nisi et nunc faucibus bibendum. Suspendisse ac felis
                                metus. Duis eget leo euismod, gravida erat eget, ultrices est.
                                Interdum et malesuada.
                            </p>
                            <Button variant='primary'>Learn More</Button>

                            <Accordion defaultActiveKey='0' flush>
                                <Accordion.Item eventKey='0'>
                                    <Accordion.Header>
                                        <div className='accordion-icon'>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={['light', 'binoculars']}
                                                className='primary'
                                            />
                                        </div>
                                        Monitoring
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Cras vitae nisi et nunc faucibus bibendum. Suspendisse ac
                                        felis metus. Duis eget leo euismod, gravida erat eget,
                                        ultrices est. Interdum et malesuada.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='1'>
                                    <Accordion.Header>
                                        <div className='accordion-icon'>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={['light', 'memo-circle-info']}
                                                className='primary'
                                            />
                                        </div>
                                        Audit Log
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Cras vitae nisi et nunc faucibus bibendum. Suspendisse ac
                                        felis metus. Duis eget leo euismod, gravida erat eget,
                                        ultrices est. Interdum et malesuada.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='2'>
                                    <Accordion.Header>
                                        <div className='accordion-icon'>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={['light', 'binary-lock']}
                                                className='primary'
                                            />
                                        </div>
                                        Advanced Encryption
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Cras vitae nisi et nunc faucibus bibendum. Suspendisse ac
                                        felis metus. Duis eget leo euismod, gravida erat eget,
                                        ultrices est. Interdum et malesuada.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='3'>
                                    <Accordion.Header>
                                        <div className='accordion-icon'>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={['light', 'shield-keyhole']}
                                                className='primary'
                                            />
                                        </div>
                                        Vulnerability Scanning
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Cras vitae nisi et nunc faucibus bibendum. Suspendisse ac
                                        felis metus. Duis eget leo euismod, gravida erat eget,
                                        ultrices est. Interdum et malesuada.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='4'>
                                    <Accordion.Header>
                                        <div className='accordion-icon'>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={['light', 'tower-observation']}
                                                className='primary'
                                            />
                                        </div>
                                        Intrusion &amp; Malware Detection
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Cras vitae nisi et nunc faucibus bibendum. Suspendisse ac
                                        felis metus. Duis eget leo euismod, gravida erat eget,
                                        ultrices est. Interdum et malesuada.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col lg={{ span: 6, offset: 1 }} className='pt-md-5'>
                            <img
                                src='/images/photo-placeholder.png'
                                className='photo photo-shadow-right mt-4'
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='content-section gradient-light-top mb-5'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg='6' md='8' sm='10' className='text-center'>
                            <h2>
                                <small>Lorem Ipsum</small>User Access Controls
                            </h2>
                            <p>
                                Cras vitae nisi et nunc faucibus bibendum. Suspendisse ac felis
                                metus. Duis eget leo euismod, gravida erat eget, ultrices est.
                                Interdum et malesuada.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Tabs>
                                <Tab
                                    eventKey='researchers'
                                    title={
                                        <>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={['light', 'id-card']}
                                                className='primary'
                                                size='xl'
                                            />
                                            User Control
                                        </>
                                    }
                                >
                                    <Container>
                                        <Row className='justify-content-center'>
                                            <Col sm='5'>
                                                <h2>User Control</h2>
                                                <p>
                                                    Cras vitae nisi et nunc faucibus bibendum.
                                                    Suspendisse ac felis metus. Duis eget leo
                                                    euismod, gravida erat eget, ultrices est.
                                                    Interdum et malesuada.
                                                </p>
                                                <ul className='list-checkmarks'>
                                                    <li>
                                                        Cras vitae nisi et nunc faucibus bibendum.
                                                    </li>
                                                    <li>
                                                        Cras vitae nisi et nunc faucibus bibendum.
                                                    </li>
                                                    <li>
                                                        Cras vitae nisi et nunc faucibus bibendum.
                                                    </li>
                                                    <li>
                                                        Cras vitae nisi et nunc faucibus bibendum.
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col sm={{ span: 6, offset: 1 }}>
                                                <img
                                                    src='/images/dashboard.png'
                                                    className='photo photo-shadow-right mt-4'
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab>
                                <Tab
                                    eventKey='providers'
                                    title={
                                        <>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={['light', 'user-lock']}
                                                className='primary'
                                                size='xl'
                                            />
                                            Secure Authentication
                                        </>
                                    }
                                >
                                    <Container>
                                        <Row className='justify-content-center'>
                                            <Col sm='5'>
                                                <h2>Secure Authentication</h2>
                                                <p>
                                                    Cras vitae nisi et nunc faucibus bibendum.
                                                    Suspendisse ac felis metus. Duis eget leo
                                                    euismod, gravida erat eget, ultrices est.
                                                    Interdum et malesuada.
                                                </p>
                                                <ul className='list-checkmarks'>
                                                    <li>
                                                        Cras vitae nisi et nunc faucibus bibendum.
                                                    </li>
                                                    <li>
                                                        Cras vitae nisi et nunc faucibus bibendum.
                                                    </li>
                                                    <li>
                                                        Cras vitae nisi et nunc faucibus bibendum.
                                                    </li>
                                                    <li>
                                                        Cras vitae nisi et nunc faucibus bibendum.
                                                    </li>
                                                </ul>
                                            </Col>
                                            <Col sm={{ span: 6, offset: 1 }}>
                                                <img
                                                    src='/images/patient-and-doctor.jpg'
                                                    className='photo photo-shadow-right mt-4'
                                                    alt='Doctor checking joints of female patient'
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className='content-section gradient-light-top mb-5' id='uses'>
                <Container>
                    <Row className='justify-content-center mb-5'>
                        <Col lg='7' md='9' className='text-center'>
                            <h2>
                                <small>Lorem Ipsum Dolor</small>Quality Assurance
                            </h2>
                            <p className='lg-alt'>
                                Cras vitae nisi et nunc faucibus bibendum. Suspendisse ac felis
                                metus. Duis eget leo euismod, gravida erat eget, ultrices est.
                                Interdum et malesuada.
                            </p>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col md='4' className='text-center mb-5 mb-md-0'>
                            <h4>Lorem Ipsum</h4>
                            <p>
                                Cras vitae nisi et nunc faucibus bibendum. Suspendisse ac felis
                                metus. Duis eget leo euismod, gravida erat eget, ultrices est.
                                Interdum et malesuada.
                            </p>
                        </Col>
                        <Col md='4' className='text-center mb-5 mb-md-0'>
                            <h4>Lorem Ipsum</h4>
                            <p>
                                Cras vitae nisi et nunc faucibus bibendum. Suspendisse ac felis
                                metus. Duis eget leo euismod, gravida erat eget, ultrices est.
                                Interdum et malesuada.
                            </p>
                        </Col>
                        <Col md='4' className='text-center mb-5 mb-md-0'>
                            <h4>Lorem Ipsum</h4>
                            <p>
                                Cras vitae nisi et nunc faucibus bibendum. Suspendisse ac felis
                                metus. Duis eget leo euismod, gravida erat eget, ultrices est.
                                Interdum et malesuada.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* <WaitingList /> */}
        </>
    );
};

export default ResourcesSecurity;
