import React from 'react';

const KeyRemindersRecap = (props) => {
    return (
        <>
            <p>Always remember the following key reminders:</p>
            <ol>
                <li>Stick to the script.</li>
                <li>Make sure harnesses are firmly in place.</li>
                <li>Avoid placing hip harness too high.</li>
                <li>Watch harnesses to make sure they stay in the correct position.</li>
                <li>Help participants understand how to keep their hips still.</li>
                <li>Start all recordings in the starting position.</li>
                <li>
                    Make sure participants do not pause when passing through the starting position.
                </li>
                <li>
                    Do not encourage participants to move farther or faster than they feel
                    comfortable.
                </li>
            </ol>
        </>
    );
};

export default KeyRemindersRecap;
