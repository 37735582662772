import MotionEvaluationVideo from '../components/knowledge_center/motion_evaluation/MotionEvaluationVideo';
import CreateAnAccount from '../components/knowledge_center/quick_start/CreateAnAccount';
import InstallDesktopApplication from '../components/knowledge_center/quick_start/InstallDesktopApplication';
import SignIn from '../components/knowledge_center/quick_start/SignIn';
import CoursePurpose from '../components/knowledge_center/motion_evaluation/CoursePurpose';

import CreatingSampleEvaluation from '../components/knowledge_center/motion_evaluation/CreatingSampleEvaluation';
import EvaluationOverview from '../components/knowledge_center/motion_evaluation/EvaluationOverview';
import EvaluationPurpose from '../components/knowledge_center/motion_evaluation/EvaluationPurpose';
import Safety from '../components/knowledge_center/motion_evaluation/Safety';
import KeyRemindersRecap from '../components/knowledge_center/motion_evaluation/KeyRemindersRecap';
import KnownIssues from '../components/knowledge_center/motion_evaluation/KnownIssues';
import TestOperatorsRole from '../components/knowledge_center/motion_evaluation/TestOperatorsRole';

export const ArticleGroups = () => {
    return [
        {
            id: `quick-start`,
            header: `Quick Start`,
            iconName: 'rabbit-running',
            articles: [
                {
                    id: 'create-an-account',
                    header: `Create an Account`,
                    component: <CreateAnAccount />,
                },
                {
                    id: 'sign-in',
                    header: `Sign In`,
                    component: <SignIn />,
                },
                {
                    id: 'install-desktop-application',
                    header: `Install Desktop Application`,
                    component: <InstallDesktopApplication />,
                },
            ],
        },
        {
            id: `motion-evaluations`,
            header: `Motion Evaluations`,
            iconName: 'person-running',
            articles: [
                {
                    id: 'course-purpose',
                    header: `1.1 - Course Purpose`,
                    component: <CoursePurpose />,
                },
                {
                    id: 'evaluation-purpose',
                    header: `1.2 - Evaluation Purpose`,
                    component: <EvaluationPurpose />,
                },
                {
                    id: 'evaluation-overview',
                    header: `1.3 - Evaluation Overview`,
                    component: <EvaluationOverview />,
                },
                {
                    id: 'safety',
                    header: `1.4 - Safety`,
                    component: <Safety />,
                },
                {
                    id: 'known-issues',
                    header: `1.5 - Known Issues`,
                    component: <KnownIssues />,
                },
                {
                    id: 'required-equipment',
                    header: `2.1 - Required Equipment`,
                    component: (
                        <MotionEvaluationVideo
                            src={`/motion_evaluation/2.1 - Required Equipment.mp4`}
                        />
                    ),
                },
                {
                    id: 'motion-kit-overview',
                    header: `2.2 - Motion Kit Overview`,
                    component: (
                        <MotionEvaluationVideo
                            src={`/motion_evaluation/2.2 - Motion Kit Overview.mp4`}
                        />
                    ),
                },
                {
                    id: 'safety-precautions',
                    header: `3.1 - Safety Precautions`,
                    component: (
                        <MotionEvaluationVideo
                            src={`/motion_evaluation/3.1 - Safety Precautions.mp4`}
                        />
                    ),
                },
                {
                    id: 'creating-sample-evaluation',
                    header: `3.2 - Creating a Sample Evaluation`,
                    component: <CreatingSampleEvaluation />,
                },
                {
                    id: 'software-overview',
                    header: `3.3 - Software Overview`,
                    component: (
                        <MotionEvaluationVideo
                            src={`/motion_evaluation/3.3 - Software Overview.mp4`}
                        />
                    ),
                },
                {
                    id: 'participant-introduction',
                    header: `3.4 - Participant Introduction`,
                    component: (
                        <MotionEvaluationVideo
                            src={`/motion_evaluation/3.4 - Participant Introduction.mp4`}
                        />
                    ),
                },
                {
                    id: 'sensor-configuration',
                    header: `3.5 - Sensor Configuration`,
                    component: (
                        <MotionEvaluationVideo
                            src={`/motion_evaluation/3.5 - Sensor Configuration.mp4`}
                        />
                    ),
                },
                {
                    id: 'head-harness-placement',
                    header: `3.6 - Head Harness Placement`,
                    component: (
                        <MotionEvaluationVideo
                            src={`/motion_evaluation/3.6 - Head Harness Placement.mp4`}
                        />
                    ),
                },
                {
                    id: 'back-harness-placement',
                    header: `3.7 - Back Harness Placement`,
                    component: (
                        <MotionEvaluationVideo
                            src={`/motion_evaluation/3.7 - Back Harness Placement.mp4`}
                        />
                    ),
                },
                {
                    id: 'hip-harness-placement',
                    header: `3.8 - Hip Harness Placement`,
                    component: (
                        <MotionEvaluationVideo
                            src={`/motion_evaluation/3.8 - Hip Harness Placement.mp4`}
                        />
                    ),
                },
                {
                    id: 'test-operators-role',
                    header: `4.1 - Test Operator's Role`,
                    component: <TestOperatorsRole />,
                },
                {
                    id: 'motion-introduction',
                    header: `4.2 - Motion Introduction`,
                    component: (
                        <MotionEvaluationVideo
                            src={`/motion_evaluation/4.2 - Motion Introduction.mp4`}
                        />
                    ),
                },
                {
                    id: 'motion-procedures',
                    header: `4.3 - Motion Procedures`,
                    component: (
                        <MotionEvaluationVideo
                            src={`/motion_evaluation/4.3 - Motion Procedures.mp4`}
                        />
                    ),
                },
                {
                    id: 'recording-motions',
                    header: `4.4 - Recording Motions`,
                    component: (
                        <MotionEvaluationVideo
                            src={`/motion_evaluation/4.4 - Recording Motions.mp4`}
                        />
                    ),
                },
                {
                    id: 'submission-and-cleanup',
                    header: `4.5 - Submission and Cleanup`,
                    component: (
                        <MotionEvaluationVideo
                            src={`/motion_evaluation/4.5 - Submission and Cleanup.mp4`}
                        />
                    ),
                },
                {
                    id: 'key-reminders',
                    header: `5.1 - Key Reminders`,
                    component: (
                        <MotionEvaluationVideo src={`/motion_evaluation/5.1 - Key Reminders.mp4`} />
                    ),
                },
                {
                    id: 'key-reminders-recap',
                    header: `5.2 - Key Reminders Recap`,
                    component: <KeyRemindersRecap />,
                },
            ],
        },
    ];
};
