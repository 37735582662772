import React from 'react';

const KnownIssues = (props) => {
    return (
        <>
            <p>
                There are a couple minor known issues with the Conity Motion Kit and Conity Desktop
                Application that you should be aware of. Note that none of these issues should stop
                you from performing data collections but may be disruptive to clinical operations if
                you are not prepared to deal with them quickly.
            </p>
            <h2>1) Conity Desktop Application White Screen</h2>
            <p>
                You may occasionally get a completely blank white screen when attempting to record
                or save a motion due to a software crash. Note that this is most likely to happen
                immediately after data is saved due to a known software bug that we are working with
                the sensor manufacturer to resolve. Most times when this happens, your data will
                already be saved, and you will not have to re-record the last motion. However,
                without the following tip, this can be a pain to recover from as the only obvious
                course of action is to close the software, re-open, re-login, and re-navigate to
                your participant’s motion evaluation (which can take 1-2 minutes).
            </p>
            <p>
                To easily and reliably recover from this issue without having to close the software
                and re-navigate, make sure the software window is in focus by clicking on it and hit
                CTRL + R on your keyboard. This command “refreshes” the window and is a handy hotkey
                for any browser (go ahead and try it in Chrome, Firefox, or Edge to get used to it).
            </p>
            <h2>2) Conity Motion Kit Sensor Harness Clips</h2>
            <p>
                Note that the fit between the sensors and harness clips is currently a bit too
                tight. This is due to 3D-printing and painting manufacturing process used for these
                prototype sensors and will be resolved in future batches when parts are injection
                molded.
            </p>
            <p>
                In the meantime, just be sure to push the sensor all the way into the harness clip
                when using the system. The clip was designed to give an audible “click” when the
                sensor is fully in, however, due to the paint this click may not occur and you will
                need to inspect the sensor visually to ensure it is fully installed.
            </p>
            <p>
                Also, since the sensors will be quite tight in the harness clip, be careful when
                removing the sensors to not drop them as you pull them out.
            </p>
        </>
    );
};

export default KnownIssues;
