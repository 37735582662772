import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Privacy = (props) => {
    const navigate = useNavigate();
    console.log('test');
    return (
        <>
            <div className='masthead'>
                <Container className='content pt-5'>
                    <Row>
                        <Col className='text-center'>
                            <h1>Privacy Notice</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='content-section pt-0'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg='8' md='10'>
                            <h2>What's covered</h2>
                            <p>
                                This privacy notice for The Ohio State University’s Conity Service
                                Team (“we”, “us”, or “our”) describes how and why we might collect,
                                store, use, and/or share your information when you use Conity
                                (“services”), which is owned and operated by The Ohio State
                                University, such as when you:
                            </p>
                            <ul>
                                <li>
                                    Visit our public website at{' '}
                                    <a href='https://conity.com'>https://conity.com</a>, or any
                                    website of ours that links to this privacy notice
                                </li>
                                <li>
                                    Visit our web application at{' '}
                                    <a href='https://app.conity.com'>https://app.conity.com</a>
                                </li>
                                <li>
                                    Use the Conity Desktop Application, which can be downloaded at{' '}
                                    <a href='https://conity.com/resources/downloads'>
                                        https://conity.com/resources/downloads
                                    </a>
                                </li>
                                <li>
                                    Engage with our team in other relevant ways such as seeking
                                    support, enquiring about sales, or discussing collaborations
                                </li>
                            </ul>
                            <h2>What's not covered</h2>
                            <p>
                                Like Conity, some teams within Ohio State have a specific privacy
                                statement for their services. See The Ohio State University’s
                                general{' '}
                                <a href='https://it.osu.edu/privacy/web-privacy-statement'>
                                    {' '}
                                    Web Privacy Statement
                                </a>{' '}
                                to learn more.
                            </p>
                            <h2>Protecting your personal information</h2>
                            <p>
                                We are committed to keeping your personal information safe. We have
                                dedicated teams who work to look after your information and privacy.
                            </p>
                            <p>
                                Our services follow the{' '}
                                <a href='https://cybersecurity.osu.edu/sites/default/files/institutionaldatapolicy.pdf'>
                                    Ohio State Institutional Data Policy
                                </a>
                                . Also, all of our teams follow the{' '}
                                <a href='https://cybersecurity.osu.edu/sites/default/files/responsible-use-of-university-computing-and-network-resources-policy.pdf'>
                                    Responsible Use of University Computing and Network Resources
                                    Policy
                                </a>
                                . But, no service can be completely secure – if you think your
                                Conity account or password is at risk, like if someone found out
                                your password, please contact us immediately at{' '}
                                <a href='mailto:privacy@conity.com'>privacy@conity.com</a>. There
                                are things we can do to keep the information you entered secure.
                            </p>
                            <h2>Types of personal information Conity collects</h2>
                            <h3>Personal information users provide to Conity</h3>
                            <ul>
                                <li>Information such as name, email address, or phone number</li>
                                <li>
                                    User affiliation with entities such as organizations, projects,
                                    other users, user groups, events, and captured data
                                </li>
                                <li>
                                    Application preferences, settings, or interests captured by
                                    Conity
                                </li>
                                <li>
                                    All of your interactions with Conity services, including every
                                    page you visit, button you click, the data you view, the data
                                    you help capture, and the data you modify
                                </li>
                            </ul>
                            <h3>Research subject data entered into Conity</h3>
                            <p>
                                Currently, users who enter research participant data into Conity
                                must do so according to an approved Institutional Review Board
                                protocol that includes participant consent and authorization.
                            </p>
                            <ul>
                                <li>
                                    Depending on the project, use case, or study protocol,
                                    participant information captured in Conity may come from many
                                    sources such as:
                                </li>
                                <ul>
                                    <li>
                                        Standardized or custom (created by user) questionnaires that
                                        participants fill out
                                    </li>
                                    <li>
                                        Standardized or custom (created by user) forms that users
                                        fill out on behalf of participants
                                    </li>
                                    <li>Conity sensors</li>
                                    <li>Copies of Electronic Health Records (EHRs)</li>
                                    <li>
                                        Biomedical imaging technologies such as CT, MRI, or X-ray
                                    </li>
                                    <li>Other approved digital or paper records</li>
                                </ul>
                                <li>
                                    Depending on the project, use case, or study protocol, the many
                                    types of participant data captured may include information such
                                    as:
                                </li>
                                <ul>
                                    <li>
                                        Identifiers such as Study ID, Medical Record Number (MRN),
                                        name, birthdate (age), mailing address, zip code, email
                                        address, or phone number
                                    </li>
                                    <li>
                                        Timing of events such as data collections, doctor visits,
                                        procedure dates, hospital admission, discharge, or death
                                    </li>
                                    <li>
                                        Demographics such as sex, gender, race, ethnicity,
                                        relationship status, educational level, household income or
                                        socioeconomic status
                                    </li>
                                    <li>Height, weight, or other anthropometric measurements</li>
                                    <li>Employment status and occupational exposures</li>
                                    <li>
                                        Patient-reported outcomes or questionnaire answers across a
                                        wide variety of biopsychosocial domains
                                    </li>
                                    <li>
                                        Information typically included in patient health records
                                        such as medical history, providers seen, provider notes,
                                        symptoms, diagnoses, diagnostic codes (ICD-10), diagnostic
                                        tests, laboratory tests, medications, treatments given,
                                        procedures performed, or devices used as well as
                                        complications
                                    </li>
                                    <li>
                                        Biomedical images and image reports, including from
                                        technologies such as CT, MRI, and X-ray
                                    </li>
                                    <li>Genomics data</li>
                                    <li>
                                        Three-dimensional motion of various body segments or joints
                                    </li>
                                    <li>Muscle or brain activity</li>
                                    <li>Forces or moments</li>
                                    <li>Blood oxygenation levels</li>
                                    <li>Body composition</li>
                                    <li>
                                        Bone density data via Dual energy X-Ray Absorptiometry
                                        (DEXA) or Quantitative CT (QcT)
                                    </li>
                                    <li>Magnetic Resonance spectroscopy or elastography data</li>
                                    <li>Other wearable data such as actigraphs </li>
                                </ul>
                            </ul>
                            <h3>
                                Information from devices such as computers, laptops, tablets, or
                                phones
                            </h3>
                            <ul>
                                <li>Internet Protocol (IP) addresses</li>
                                <li>Media Access Control (MAC) addresses</li>
                                <li>Other device identifiers</li>
                                <li>Device location</li>
                                <li>Device operating system and version</li>
                                <li>Browser type and version</li>
                                <li>
                                    Software platform types and versions, such as for Java or Flash
                                </li>
                            </ul>
                            <h3>Information from Conity devices such as receivers and sensors</h3>
                            <ul>
                                <li>Serial number (SN)</li>
                                <li>Model number (REF)</li>
                                <li>Firmware version</li>
                                <li>Signal strength</li>
                                <li>Battery status</li>
                            </ul>
                            <h2>How we use collected information and why we collect it</h2>
                            <h3>To operate the Conity service</h3>
                            <p>
                                The large majority of data we collect is captured primarily to
                                operate the Conity service and provide value to users.
                            </p>
                            <p>
                                Data may also be used to provide service, train users, or fix
                                reported issues.
                            </p>
                            <p>
                                Additionally, data may be used to help customize the Conity service
                                to each user or organization’s needs.
                            </p>
                            <h3>To maintain safety, security, reliability, and compliance</h3>
                            <p>
                                Much of the information collected, such as user identities, device
                                identities, user activity, and data history are captured to keep
                                users safe, maintain security, promote reliability of stored data,
                                and satisfy extensive compliance requirements.
                            </p>
                            <h3>To send critical updates to users</h3>
                            <p>
                                Contact information is required to notify users when critical
                                updates to software, policies, or notices have been made.
                            </p>
                            <p>
                                Contact information may also be needed to notify users in the case
                                of a security breach or product recall.
                            </p>
                            <h3>To alert users about new products or updated features</h3>
                            <p>
                                Users may be alerted about new products, services, or features they
                                may be interested in.
                            </p>
                            <p>
                                Users may also be alerted when changes to existing products are made
                                or new features are available.
                            </p>
                            <h3>To improve the Conity service and grow reference databases</h3>
                            <p>
                                Data we capture may be used to generally improve the overall Conity
                                service offering.
                            </p>
                            <p>
                                Captured data may be added to Conity participant reference or
                                benchmark databases to conduct additional research or to develop new
                                features, algorithms, biomarkers, products, or services.
                            </p>
                            <p>
                                Note that any additional relevant agreements or contracts between
                                the user, their participants, their organization, their
                                Institutional Review Board (IRB), or Conity that limit the use of
                                this data must be clearly provided to us in advance and must receive
                                confirmation in writing that we agree to these additional limits.
                            </p>
                            <h2>Sharing personal information with other entities</h2>
                            <p>
                                We may need to share your personal information in the following
                                situations. Note that any additional relevant agreements or
                                contracts between the user, their participants, their organization,
                                their Institutional Review Board (IRB), or Conity that limit the use
                                of this data will be adhered to when sharing this data, assuming we
                                have been properly notified in advance and have agreed in writing to
                                these additional limits.
                            </p>
                            <p>
                                Note that when data must be shared, we will de-identify or code data
                                sets when possible (e.g. if they are not needed to complete the
                                purpose of the share) to minimize risk to users and participants.
                            </p>
                            <h3>Business Transfers</h3>
                            <p>
                                We may share or transfer your information in connection with, or
                                during negotiations of, any spin-out, merger, asset sale, or
                                acquisition of all or a portion of the Conity service to another
                                company.
                            </p>
                            <h3>Affiliates</h3>
                            <p>
                                We may share your information with our affiliates, in which case we
                                will require those affiliates to honor this privacy notice.
                                Affiliates may include The Ohio State University, joint venture
                                partners, or other partner companies that we control or that are
                                under common control with us.
                            </p>
                            <h3>Business Partners, Collaborators, and Sponsors</h3>
                            <p>
                                We may share your information with our business partners to offer
                                you certain products, services, or promotions.
                            </p>
                            <p>
                                Your data may also be shared with research collaborators and
                                sponsors as part of ongoing research collaborations.
                            </p>
                            <h3>Other Conity Users</h3>
                            <p>
                                Note that some users within your organization and on the Conity team
                                with certain privileges may be able to access your data in Conity.
                            </p>
                            <h3>Federal and State Agencies</h3>
                            <p>
                                In some situations, regulatory agencies may require us to provide
                                them data about users and participants. Such agencies may include
                                the U.S. Food and Drug Administration (FDA), The Ohio State
                                University Institutional Review Board (IRB), the Department of
                                Health and Human Services (DHHS), and other federal and state
                                entities.
                            </p>
                            <h2>
                                How long will we keep your information, and how can you request to
                                delete it?
                            </h2>
                            <h3>Information retainment</h3>
                            <p>
                                Information will be kept according to university retention schedules
                                and regulatory requirements.
                            </p>
                            <p>
                                While inactive users and archived data may generally be hidden from
                                most users, note that some users with elevated privileges from your
                                organization, some Conity team members, and some regulatory or
                                compliance agents may still be able to access this data.
                            </p>
                            <p>
                                Note that backups are created for most collected data to maintain
                                reliability for the application. In the case that data is deleted,
                                it is possible that a copy may remain in these backups.
                            </p>
                            <h3>Submitting requests to delete your data</h3>
                            <p>
                                Users may submit a request to delete their data permanently from the
                                Conity platform by contacting support@conity.com.
                            </p>
                            <p>
                                Note that the Conity team makes no guarantees that they will be able
                                to grant this request and expect that approval of these requests
                                will be rare due to high legal, regulatory, service, and security
                                requirement barriers.
                            </p>
                            <h2>Updates to this privacy notice</h2>
                            <p>
                                If our privacy practices change, we will post the changes right here
                                on this page to notify you we made the change. Additionally, we may
                                contact users via their provided email addresses to notify them of
                                significant changes.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* <WaitingList /> */}
        </>
    );
};

export default Privacy;
