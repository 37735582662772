import { Button, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ResourcesSupport = (props) => {
    const navigate = useNavigate();
    return (
        <>
            <div className='masthead pb-0'>
                <Container>
                    <Row className='justify-content-center mb-5'>
                        <Col lg='7' md='9' className='text-center'>
                            <h1>Support</h1>
                            <p className='lg'>Need help? Contact support.</p>
                            <p className='lg'>
                                <a href='mailto:support@conity.com'>support@conity.com</a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='content-section'>
                <Container>
                    <Row className='justify-content-center mb-5 pb-5'>
                        <Col md='10' lg='8'>
                            <Form className='on-white'>
                                <Container>
                                    <Row>
                                        <Col sm='6'>
                                            <FloatingLabel
                                                controlId='support-name'
                                                label='Your Name*'
                                                className='mb-3'
                                            >
                                                <Form.Control
                                                    type='text'
                                                    placeholder='Your Name*'
                                                />
                                            </FloatingLabel>
                                        </Col>
                                        <Col sm='6'>
                                            <FloatingLabel
                                                controlId='support-email'
                                                label='Email Address*'
                                                className='mb-3'
                                            >
                                                <Form.Control
                                                    type='email'
                                                    placeholder='Your Email*'
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FloatingLabel
                                                controlId='support-company'
                                                label='Company Name*'
                                                className='mb-3'
                                            >
                                                <Form.Control
                                                    type='text'
                                                    placeholder='Company Name*'
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FloatingLabel
                                                controlId='waiting-role'
                                                label='Reason for Reaching Out*'
                                                className='mb-3'
                                            >
                                                <Form.Select aria-label='Reason for reaching out'>
                                                    <option></option>
                                                    <option value=''>Trouble logging in</option>
                                                    <option value=''>
                                                        Issues with sensors, receiver, or other
                                                        Motion Kit hardware
                                                    </option>
                                                    <option value=''>Report software bug</option>
                                                    <option value=''>
                                                        Need assistance with software setup
                                                    </option>
                                                    <option value=''>Other</option>
                                                </Form.Select>
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FloatingLabel
                                                controlId='waiting-comment'
                                                label='How can we help? Be as specific as possible.*'
                                                className='mb-3'
                                            >
                                                <Form.Control
                                                    as='textarea'
                                                    placeholder='How can we help? Be as specific as possible.'
                                                    style={{ height: '200px' }}
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='text-center'>
                                            <Button variant='primary'>Submit</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default ResourcesSupport;
