import React from 'react';

const Safety = (props) => {
    return (
        <>
            <div className='warning warning-right'>
                <p>
                    Throughout this course, a caution symbol will be used to highlight critical
                    safety instructions. Pay special attention to instructions with this symbol to
                    keep the participant and yourself safe.
                </p>
            </div>
            <p>
                Note that the instructions provided throughout this course and in the software are
                designed specifically to make sure the evaluation is performed safely, accurately,
                and consistently and should be followed at all times.
            </p>
            <div className='warning warning-left'>
                <p>
                    <strong>CAUTION:</strong> Do NOT intentionally deviate from the instructions as
                    doing so may result in harm or invalid results.
                </p>
            </div>
        </>
    );
};

export default Safety;
