import React from 'react';

const MotionEvaluationVideo = (props) => {
    if (props.src) {
        return (
            <video key={props.src} controls autoPlay className='w-100'>
                <source src={props.src} type='video/mp4' />
            </video>
        );
    }
};

export default MotionEvaluationVideo;
