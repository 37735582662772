import React from 'react';

const CreateAnAccount = (props) => {
    return (
        <>
            <p>
                All Users must access Conity through a registered user account. User accounts are
                managed by your Organization Administrator(s).
            </p>
            <h3>New Organization</h3>
            <p>
                If you are not part of an existing organization that is using Conity, you will need
                to request that a new organization be created.
            </p>
            <h3>Existing Organization</h3>
            <p>
                If your organization already exists, you will need to send a request to your
                Organization Administrator to create an account for you. If you do not know who your
                Organization Administrator is, please reach out to support@conity.com for support.
            </p>
            <p>
                All Users must access Conity through a registered user account. User accounts are
                managed by your Organization Administrator(s).
            </p>
        </>
    );
};

export default CreateAnAccount;
