import React from 'react';
import InstallationInstructions from '../../InstallationInstructions';
import SystemRequirementsGrid from '../../SystemRequirementsGrid';

const InstallDesktopApplication = (props) => {
    return (
        <>
            <p>
                If you plan to collect motion evaluations, you will need to install the Conity
                Desktop application on a device that meets system requirements using the steps and
                information provided below. If you do not plan to collect motion evaluations, you do
                not need to install any software on your device and can access the Conity web
                application directly from any modern browser.
            </p>
            <div className='no-col'>
                <InstallationInstructions />
            </div>
            <div className='mt-5 text-left'>
                <SystemRequirementsGrid />
            </div>
        </>
    );
};

export default InstallDesktopApplication;
